import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import Guarantee from '../../components/Guarantee';
import prod1 from '../../assets/images/carousel/prod1.jpg';
import prod2 from '../../assets/images/carousel/prod2.jpg';
import prod3 from '../../assets/images/carousel/prod3.jpg';
import prod4 from '../../assets/images/carousel/prod4.jpg';
import prevButton from '../../assets/images/back.svg';
import nextButton from '../../assets/images/forward.svg';
import sp1 from '../../assets/images/spots1.svg';
import sp2 from '../../assets/images/spots2.svg';

const BP1 = '@media (max-width: 959px)';
const BP2 = '@media (max-width: 699px)';
const BP3 = '@media (max-width: 599px)';

const IMAGES = [prod1, prod2, prod3, prod4];

const styles = {
	topContainer: {
		width: '100%',
		pt: '61px',
		pb: '191px',
		pl: '16px',
		pr: '16px',
		backgroundColor: '#7A8F99',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0 32px',
		boxSizing: 'border-box',
	},
	carouselContainer: {
		flex: 1,
		maxWidth: '936px',
		borderRadius: '12px',
		overflow: 'hidden',
	},
	carouselImageContainer: {
		maxWidth: '936px',
		outline: 'none',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dotsContainer: {
		bottom: 0,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: '0 10px',
		paddingBottom: '25px',
	},
	dot: {
		width: '10px',
		height: '10px',
		backgroundColor: 'primary.main',
	},
	navButton: {
		backgroundColor: '#FCFCFC',
		borderRadius: '3px',
		p: '8px',
		pb: '4px',
		boxShadow: '0px 1px 2px #677D8433',
		cursor: 'pointer',
		[BP2]: {
			display: 'none',
		},
	},
	centerContainer: {
		width: '100%',
		pl: '16px',
		pr: '16px',
		backgroundColor: '#FFF',
		position: 'relative',
		boxSizing: 'border-box',
	},
	textCard: {
		width: '100%',
		maxWidth: '1128px',
		backgroundColor: '#FFF',
		borderRadius: '12px',
		boxShadow: '0px 50px 80px #0D988C1A',
		position: 'relative',
		top: '-130px',
		// mt: '-130px',
		boxSizing: 'border-box',
		ml: 'auto',
		mr: 'auto',
		pt: '83px',
		pl: '96px',
		pr: '96px',
		pb: '73px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		transition: 'all .3s',
		[BP1]: {
			pl: '20px',
			pr: '20px',
		},
	},
	title: {
		fontFamily: 'fira-bold',
		fontSize: '80px',
		lineHeight: '80px',
		letterSpacing: '-1.6px',
		textAlign: 'center',
		color: 'text.blue',
		marginTop: '60px',
		transition: 'all .3s',
		[BP3]: {
			fontSize: '60px',
			lineHeight: '60px',
		},
	},
	text: {
		fontFamily: 'fira',
		fontSize: '24px',
		textAlign: 'center',
		color: 'text.dark',
		lineHeight: '40px',
		marginTop: '113px',
		transition: 'all .3s',
		[BP3]: {
			fontSize: '20px',
			lineHeight: '33px',
		},
	},
};

const sliderConfig = {
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	vertical: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: true,
	dots: true,
	appendDots: (dots) => (
		<Box sx={styles.dotsContainer}>
			{dots.map((dot, i) => (
				<Box key={i.toString()} sx={{ ...styles.dot, opacity: dot.props.className ? 1 : 0.3 }} />
			))}
		</Box>
	),
};

const renderNavButton = (isNext, onClick) => (
	<Box sx={styles.navButton} onClick={onClick}>
		<img src={isNext ? nextButton : prevButton} alt={isNext ? 'Next' : 'Previous'} />
	</Box>
);

const Product = () => {
	const sliderRef = useRef(null);
	const { t } = useTranslation(['products']);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box>
			<Box sx={styles.topContainer}>
				{renderNavButton(false, () => sliderRef.current.slickPrev())}
				<Box sx={styles.carouselContainer}>
					<Slider {...sliderConfig} ref={sliderRef}>
						{IMAGES.map((image, i) => (
							<Box key={i.toString()} sx={styles.carouselImageContainer}>
								<img src={image} style={{ width: '100%' }} alt={`Carousel ${i + 1}`} />
							</Box>
						))}
					</Slider>
				</Box>
				{renderNavButton(true, () => sliderRef.current.slickNext())}
			</Box>
			<Box sx={styles.centerContainer}>
				<Box sx={styles.textCard}>
					<img src={sp1} alt='Spots' />
					<Typography sx={styles.title}>{t('title')}</Typography>
					<Typography sx={styles.text}>{t('text')}</Typography>
					<img src={sp2} style={{ marginTop: 82 }} alt='Spots' />
				</Box>
			</Box>
			<Guarantee />
		</Box>
	);
};

export default Product;
