import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const BP1 = '@media (max-width: 1359px)';
const BP2 = '@media (max-width: 1110px)';
const BP3 = '@media (max-width: 589px)';

const styles = {
	bannerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		backgroundColor: '#08364D',
		boxSizing: 'border-box',
	},
	bannerRoot: {
		width: '100%',
		maxWidth: '2560px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
		gap: '48px 28px',
		boxSizing: 'border-box',
		[BP2]: {
			flexDirection: 'column',
		},
	},
	bannerContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		paddingRight: '20px',
		paddingLeft: '20px',
		alignItems: 'flex-start',
		alignSelf: 'center',
		paddingTop: '48px',
		paddingBottom: '48px',
		boxSizing: 'border-box',
		[BP2]: {
			alignItems: 'center',
		},
	},
	bannerContentLeft: {
		alignItems: 'flex-end',
		textAlign: 'right',
		[BP2]: {
			alignItems: 'center',
			order: 1,
		},
	},
	image: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '924px',
		transition: 'all 0.3s',
		[BP1]: {
			maxWidth: '700px',
		},
		[BP2]: {
			maxWidth: 'unset',
			width: '100%',
			maxHeight: '400px',
			overflow: 'hidden',
		},
	},
	title: {
		maxWidth: '534px',
		marginBottom: '20px',
		transition: 'all 0.3s',

		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		wordBreak: 'break-word',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		[BP2]: {
			textAlign: 'center',
		},
		[BP3]: {
			fontSize: '36px',
			lineHeight: '43px',
		},
	},
	subtitle: {
		maxWidth: '534px',
		marginBottom: '80px',
		transition: 'all 0.3s',
		[BP2]: {
			textAlign: 'center',
		},
		[BP3]: {
			fontSize: '20px',
			lineHeight: '24px',
		},
	},
};

const Banner = ({
	title, subtitle, image, isRightImage, titleStyle, subtitleStyle, onClick,
}) => {
	const { t } = useTranslation(['home']);

	const bannerImage = (
		<Box sx={styles.image}>
			<img src={image} style={{ width: '100%' }} alt='Banner' />
		</Box>
	);

	return (
		<Box sx={styles.bannerContainer}>
			<Box sx={styles.bannerRoot}>
				{!isRightImage && bannerImage}
				<Box sx={{ ...styles.bannerContent, ...(isRightImage ? styles.bannerContentLeft : {}) }}>
					<Typography variant='bannerTitle' sx={{ ...styles.title, ...titleStyle }}>
						{title}
					</Typography>
					<Typography variant='bannerSubtitle' sx={{ ...styles.subtitle, ...subtitleStyle }}>
						{subtitle}
					</Typography>
					<Button
						variant='contained'
						size='large'
						sx={{
							width: '200px', height: '48px', fontFamily: 'Roboto', fontSize: '20px', borderRadius: '6px',
						}}
						onClick={onClick}
					>
						{t('nextButton')}
					</Button>
				</Box>
				{isRightImage && bannerImage}
			</Box>
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
Banner.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	image: PropTypes.any.isRequired,
	isRightImage: PropTypes.bool,
	titleStyle: PropTypes.any,
	subtitleStyle: PropTypes.any,
	onClick: PropTypes.any.isRequired,
};

Banner.defaultProps = {
	isRightImage: false,
	titleStyle: {},
	subtitleStyle: {},
};

export default Banner;
