import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import banner1 from '../../assets/images/banner1.jpg';
import banner2 from '../../assets/images/banner2.jpg';
import banner3 from '../../assets/images/banner3.jpg';
import Banner from './components/Banner';
import TextContainer from './components/TextContainer';
import Guarantee from '../../components/Guarantee';
import spots2 from '../../assets/images/spots2.svg';

const Home = () => {
	const { t } = useTranslation(['home']);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);

	const refs = [ref1, ref2, ref3];

	const scroll = (index) => {
		refs[index].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<Box>
			<Banner title={t('banner1.title')} subtitle={t('banner1.subtitle')} image={banner1} onClick={() => scroll(0)} />
			<TextContainer ref={ref1} title={t('text1.title')} text={t('text1.text')} />
			<Guarantee />
			<Banner title={t('banner2.title')} subtitle={t('banner2.subtitle')} image={banner2} isRightImage onClick={() => scroll(1)} />
			<TextContainer
				ref={ref2}
				title={t('text2.title')}
				text={t('text2.text')}
				topImage={spots2}
				style={{ paddingTop: '146px', paddingBottom: '145px' }}
			/>
			<Banner title={t('banner3.title')} subtitle={t('banner3.subtitle')} image={banner3} onClick={() => scroll(2)} />
			<TextContainer
				ref={ref3}
				title={t('text3.title')}
				text={t('text3.text')}
				style={{ paddingTop: '125px', paddingBottom: '162px' }}
			/>
			<Guarantee />
		</Box>
	);
};

export default Home;
