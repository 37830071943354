import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import gs1 from '../assets/images/guarantee-spots1.svg';
import gs2 from '../assets/images/guarantee-spots2.svg';

const BP1 = '@media (max-width: 779px)';
const BP2 = '@media (max-width: 569px)';
const BP3 = '@media (max-width: 289px)';
const BP4 = '@media (max-width: 479px)';

const styles = {
	root: {
		width: '100%',
		backgroundColor: '#F0F3F5',
		paddingTop: '37px',
		paddingBottom: '37px',
		paddingLeft: '20px',
		paddingRight: '20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxSizing: 'border-box',
		overflow: 'hidden',
	},
	title: {
		fontFamily: 'fira-bold',
		fontSize: '30px',
		lineHeight: '30px',
		color: 'text.blue',
		letterSpacing: '-1px',
		textAlign: 'center',
		transition: 'all .3s',
		[BP4]: {
			fontSize: '26px',
			lineHeight: '26px',
		},
	},
	description: {
		fontFamily: 'fira-bold',
		fontSize: '22px',
		lineHeight: '25px',
		color: 'text.blue',
		letterSpacing: '-1px',
		textAlign: 'center',
		marginTop: '21px',
		transition: 'all .3s',
		[BP4]: {
			fontSize: '20px',
			lineHeight: '22px',
		},
	},
	gContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		marginTop: '10px',
	},
	spotImage: {
		minWidth: '150px',
		[BP2]: {
			display: 'none',
		},
	},
	outer: {
		width: '320px',
		minWidth: '320px',
		height: '70px',
		padding: '10px',
		border: '2px solid #08364D',
		borderRadius: '10px',
		boxSizing: 'border-box',
		transition: 'all .3s',
		[BP2]: {
			minWidth: 'unset',
			textAlign: 'center',
		},
		[BP3]: {
			height: 'unset',
		},
	},
	inner: {
		width: '100%',
		height: '100%',
		backgroundColor: 'primary.main',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#0F2733',
		fontFamily: 'fira-bold',
		fontSize: '30px',
		lineHeight: '30px',
		borderRadius: '5px',
		boxSizing: 'border-box',
		transition: 'all .3s',
		[BP3]: {
			fontSize: '22px',
			lineHeight: '22px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			padding: '10px',
		},
	},
	line: {
		width: '264px',
		height: '4px',
		borderRadius: '10px',
		backgroundColor: '#08364D',
		[BP1]: {
			display: 'none',
		},
	},
};

const Guarantee = () => {
	const { t } = useTranslation();
	const renderSpot = (image) => (
		<Box sx={styles.spotImage}>
			<img src={image} style={{ width: '100%' }} alt='Spots' />
		</Box>
	);
	return (
		<Box sx={styles.root}>
			<Typography sx={styles.title}>{t('guarantee.title')}</Typography>
			<Box sx={styles.gContainer}>
				<Box sx={{ ...styles.line, marginRight: '-10px' }} />
				{renderSpot(gs1)}
				<Box sx={styles.outer}>
					<Box sx={styles.inner}>{t('guarantee.guarantee')}</Box>
				</Box>
				{renderSpot(gs2)}
				<Box sx={{ ...styles.line, marginLeft: '-10px' }} />
			</Box>
			<Typography sx={styles.description}>{t('guarantee.description')}</Typography>
		</Box>
	);
};

export default Guarantee;
