import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import i18n from '../lib/i18n/i18n';
import menuIcon from '../assets/images/menu.svg';
import collapseIcon from '../assets/images/collapse_icon.svg';
import logoSm from '../assets/images/logo-sm.svg';
import logoLg from '../assets/images/logo-lg.svg';

const BP1 = '@media (max-width: 1069px)';
const BP2 = '@media (max-width: 699px)';

const styles = {
	root: {
		position: 'fixed',
		zIndex: 100,
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0 70px',
		boxShadow: '0px 2px 10px #7A8F9933',
		transition: 'all .3s',
		backgroundColor: '#FFF',
		padding: '30px 20px',
		boxSizing: 'border-box',
		'&:nth:child(3)': {
			backgroundColor: '#08364D',
		},
		'&:nth:child(4)': {
			backgroundColor: '#000',
		},
		[BP1]: {
			justifyContent: 'flex-start',
			paddingTop: '21px',
			paddingBottom: '21px',
			gap: '0 50px',
		},
		[BP2]: {
			justifyContent: 'space-between',
		},
	},
	rootShrunk: {
		paddingTop: '21px',
		paddingBottom: '21px',
	},
	logo: {
		width: '200px',
		height: '180px',
		backgroundImage: `url(${logoLg})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		transition: 'all .3s',
		cursor: 'pointer',
		[BP1]: {
			order: 1,
			width: '150px',
			height: '78px',
			backgroundImage: `url(${logoSm})`,
		},
	},
	logoShrunk: {
		width: '150px',
		height: '78px',
		backgroundImage: `url(${logoSm})`,
	},
	btnContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row-reverse',
		[BP1]: {
			flex: 'unset',
			'&:nth-child(1)': {
				order: 2,
			},
			'&:nth-child(3)': {
				order: 3,
			},
		},
		[BP2]: {
			display: 'none',
		},
	},
	rightBtn: {
		flexDirection: 'row',
	},
	btn: {
		fontFamily: 'fira-bold',
		fontSize: '20px',
		color: '#08364D',
		cursor: 'pointer',
		letterSpacing: '-0.4px',
	},
	menuIcon: {
		display: 'none',
		cursor: 'pointer',
		minWidth: '40px',
		width: '50px',
		[BP2]: {
			display: 'block',
			order: 1,
		},
	},
	mobileMenuContainer: {
		width: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 200,
		boxShadow: '0px 2px 10px #7A8F9933',
	},
	collapseIcon: {
		width: '50px',
		cursor: 'pointer',
	},
	mobileMenu: {
		width: '100%',
		backgroundColor: '#FFF',
		padding: '20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxSizing: 'border-box',
		gap: '20px',
	},
	languageSelector: {
		position: 'absolute',
		right: '80px',
		[BP2]: {
			display: 'none',
		},
	},
};

const Header = ({ shrunk, onChange }) => {
	const [menuOpened, setMenuOpened] = useState(false);
	const [language, setLanguage] = useState(i18n.language);
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (menuOpened) {
			const f = () => setMenuOpened(false);
			window.addEventListener('resize', f);
			return () => {
				window.removeEventListener('resize', f);
			};
		}
		return () => {};
	}, [menuOpened]);

	useEffect(() => {
		setLanguage(i18n.language);
	}, [t]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handleScroll = () => {
				onChange(window.pageYOffset > 0);
			};
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
		return () => {};
	}, [onChange]);

	const changeLanguage = async (lang) => {
		await i18n.changeLanguage(lang);
		localStorage.setItem('lang', lang);
	};

	const handleMenu = (e) => {
		e.stopPropagation();
		setMenuOpened((state) => !state);
	};

	const handleNavigation = (path) => {
		setMenuOpened(false);
		if (location.pathname !== path) history.push(path);
	};

	const handleHome = () => handleNavigation('/');
	const handleProducts = () => handleNavigation('/products');

	return (
		<>
			<Box sx={styles.root}>
				<Box sx={styles.btnContainer}>
					<Box sx={styles.btn} onClick={handleHome}>{t('about')}</Box>
				</Box>
				<Box sx={{ ...styles.logo, ...(shrunk ? styles.logoShrunk : {}) }} onClick={handleHome} />
				<Box sx={{ ...styles.btnContainer, ...styles.rightBtn }}>
					<Box sx={styles.btn} onClick={handleProducts}>{t('products')}</Box>
				</Box>
				<Box sx={styles.menuIcon} onClick={handleMenu}>
					<img src={menuIcon} style={{ width: '100%' }} alt='Collapse icon' />
				</Box>
				<Box sx={styles.languageSelector}>
					<LanguageSelector language={language} onChange={changeLanguage} />
				</Box>
			</Box>
			<Box sx={styles.mobileMenuContainer}>
				<Collapse in={menuOpened} timeout='auto' unmountOnExit>
					<Box sx={styles.mobileMenu}>
						<Box sx={styles.collapseIcon} onClick={handleMenu}>
							<img style={{ width: '100%' }} src={collapseIcon} alt='Menu icon' />
						</Box>
						<Box sx={styles.btn} onClick={handleHome}>{t('about')}</Box>
						<Box sx={styles.btn} onClick={handleProducts}>{t('products')}</Box>
						<LanguageSelector language={language} onChange={changeLanguage} style={{ marginTop: '20px' }} />
					</Box>
				</Collapse>
			</Box>
		</>
	);
};

/* eslint-disable react/forbid-prop-types */
Header.propTypes = {
	shrunk: PropTypes.bool.isRequired,
	onChange: PropTypes.any.isRequired,
};

export default Header;
