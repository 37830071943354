import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import spots1 from '../../../assets/images/spots1.svg';

const BP1 = '@media (max-width: 589px)';

const styles = {
	root: {
		backgroundColor: '#FFF',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingLeft: '20px',
		paddingRight: '20px',
		paddingTop: '150px',
		paddingBottom: '168px',
	},
	respText: {
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		wordBreak: 'break-word',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
	},
	title: {
		fontFamily: 'fira-bold',
		fontSize: '50px',
		lineHeight: '60px',
		color: 'primary.main',
		letterSpacing: '-1px',
		marginBottom: '50px',
		textAlign: 'center',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '36px',
			lineHeight: '43px',
		},
	},
	textContainer: {
		maxWidth: '1128px',
		marginBottom: '50px',
	},
	text: {
		width: '100%',
		fontSize: '28px',
		lineHeight: '40px',
		color: 'text.dark',
		textAlign: 'center',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '24px',
			lineHeight: '34px',
		},
	},
	image: {
		width: '50px',
		marginBottom: '20px',
	},
	bottomDot: {
		width: '12px',
		height: '12px',
		backgroundColor: 'primary.main',
		borderRadius: '50%',
	},
};

// eslint-disable-next-line react/display-name
const TextContainer = forwardRef(({
	title, text, topImage, style, textStyle,
}, ref) => {
	const [rows, setRows] = useState(text.split('\n'));

	useEffect(() => {
		setRows(text.split('\n'));
	}, [text]);

	return (
		<Box sx={{ ...styles.root, ...style }}>
			<img src={topImage} style={styles.image} alt='Spots' />
			<Typography ref={ref} sx={{ ...styles.title, ...styles.respText }}>{title}</Typography>
			<Box sx={styles.textContainer}>
				{rows.map((row, i) => (
					<React.Fragment key={i.toString()}>
						<Typography sx={{ ...styles.text, ...styles.respText, ...textStyle }}>{row || <br />}</Typography>
					</React.Fragment>
				))}
			</Box>
			<Box sx={styles.bottomDot} />
		</Box>
	);
});

/* eslint-disable react/forbid-prop-types */
TextContainer.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	topImage: PropTypes.any,
	style: PropTypes.any,
	textStyle: PropTypes.any,
};

TextContainer.defaultProps = {
	topImage: spots1,
	style: {},
	textStyle: {},
};

export default TextContainer;
