import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './i18n_namespaces';

i18n.use(initReactI18next).init({
	ns: ['common'],
	resources,
	fallbackLng: 'hu',
	debug: false,
	defaultNS: 'common',
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: false,
	},
});

export default i18n;
