import * as r from './resources';

const hu = {
	common: r.commonHu,
	home: r.homeHu,
	products: r.productsHu,
};

const ro = {
	common: r.commonRo,
	home: r.homeRo,
	products: r.productsRo,
};

const resources = { hu, ro };

export default resources;
