import Home from '../views/Home/Home';
import Product from '../views/Product/Product';

const routes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/products',
		component: Product,
		exact: true,
	},
];

export default routes;
